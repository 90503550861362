<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseSlugs.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseSlugs.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseSlugs = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Products.Categories.includes('D')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Products/Categories') }}</h5>
            </CCol>
            <CCol col="6" class="d-flex justify-content-end">
              <CDropdown addTogglerClasses="btn-outline-success btn-pill" placement="bottom-start" class="d-inline-block mr-2">
                <template #toggler-content>
                  <CIcon size="sm" name="cil-library" class="mr-1" />{{ $store.getters.collection && $store.getters.collection.label || '預設資料集' }}
                </template>
                <CDropdownItem v-for="collection in $store.getters.collectionList" :key="collection._id" @click="$store.dispatch('ChangeCollection', collection)">
                  {{ collection.label }}
                </CDropdownItem>
              </CDropdown>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
              id="ProductCategoryList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Name="{item}">
              <td>
                <p class="mb-0">
                  <a class="text-info" :href="`https://${$store.state.user.permission.Domain}/products/list/${item.Slug}`" target="_blank">{{ item.Name }}</a>
                </p>
                <div v-if="Array.isArray(item.Tags)" class="Tags mt-1">
                  <CBadge v-for="(Tag, Index) in item.Tags" :key="Tag + Index" class="mr-1" color="info">
                    {{ Tag }}
                  </CBadge>
                </div>
              </td>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-Category-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
                </label>
              </td>
            </template>
            <template #Cover="{item}">
              <td>
                <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                  {{ $t('Product.Status.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton v-if="$store.state.user.permission.Permission.Products.Categories.includes('W')" color="info" size="sm" class="mr-1" v-c-tooltip="$t('Product/List.Edit')" @click="$router.push(`/products/category/detail/${item.Slug}`)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size mr-1" />編輯
                </CButton>
                <CButton class="btn btn-sm btn-secondary mr-1" v-c-tooltip="`查看分類頁面`" @click="HandleExternalLink(item.Slug)">
                  <CIcon name="cil-paper-plane" class="c-icon-custom-size mr-1"/>查看分類頁面
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "商品分類"
}
}
</route>

<script>
export default {
  name: 'ProductCategoryList',
  layout: 'manage',
  data () {
    return {
      List: [],
      AlertModel: false,
      ChooseSlugs: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Product/CategoryList.NoResults'),
        noItems: this.$t('Product/CategoryList.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Cover', label: this.$t('Product/CategoryList.Cover') },
        { key: 'Name', label: this.$t('Product/CategoryList.Name') },
        { key: 'Slug', label: this.$t('Product/CategoryList.Slug') },
        { key: 'Status', label: this.$t('Product/CategoryList.Status') },
        { key: 'Action', label: '' }
      ]
    }
  },
  created() {
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order,
        site: this.$store.getters.collection.value
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      this.$router.replace({
        path: '/products/category/list',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/delete',
        method: 'post',
        data: {
          slug: this.ChooseSlugs
        }
      }).then(() => {
        this.GetList()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.ProductCategory/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.ProductCategory/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    HandleExternalLink(slug) {
      return window.open('//' + this.$store.state.user.permission.Domain + `/products/list/${encodeURI(slug)}`, '_blank')
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseSlugs = []
    }
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#ProductCategoryList thead th:first-child {
  text-align: center;
}
#ProductCategoryList tbody td:first-child {
  vertical-align: middle;
}
</style>
